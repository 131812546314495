import React, {useState } from "react";
import FileList from "../fileList/fileList";
import RegionalFiles from "../fileList/RegionalFiles";
import { IRegionalConfig } from "../interfaces";
import { PERIODS, REGIONS } from "../../utils/constants";

function OnixFile(props: any) {

    let ausConfig: IRegionalConfig = {
        region: REGIONS.AUSTRALIA,
        periods: [PERIODS.W,PERIODS.D],
        weekly: {
            key: "WF",
            count: 1,
        },
        daily: {
            key: "D",
            count: 2 // count per set
        },
        monthly: {
            key: "M",
            count: 1,
        },
        sets: 3,
        mediums: ["P"],
        version: "3.0"
    }

    const onixFiles = (region: string) => {
        switch(region){
            case REGIONS.AUSTRALIA:
                return <RegionalFiles regionalConfig={ausConfig}/>;
            case REGIONS.US:
                return (
                    <>
                    <FileList period={"M"} type={"P"} region={props.region} version={props.version} />
                    <FileList period={"W"} type={"P"} region={props.region} version={props.version} />
                    <FileList period={"M"} type={"E"} region={props.region} version={props.version} />
                    <FileList period={"W"} type={"E"} region={props.region} version={props.version} />
                    <FileList period={"M"} type={"O"} region={props.region} version={props.version} />
                    <FileList period={"W"} type={"O"} region={props.region} version={props.version} />
                    {props.version === "3.0" && (
                        <>
                            <FileList period={"M"} type={"eMRW"} region={props.region} version={props.version} />
                            <FileList period={"W"} type={"eMRW"} region={props.region} version={props.version} />
                        </>
                    )}
                    </>
                );
            case REGIONS.UK:
                return (
                    <>
                    <FileList period={"M"} type={"P"} region={props.region} version={props.version} />
                    <FileList period={"D"} type={"P"} region={props.region} version={props.version} />
                    </>
                );
            default:
                return(
                    <>
                    <FileList period={"M"} type={"P"} region={props.region} version={props.version} />
                    <FileList period={"W"} type={"P"} region={props.region} version={props.version} />
                    </>
                );
        }
    }

    return (
        <>
            <b>ONIX {props.version}{props.label && ` / ${props.label}`}</b>
            <ul>{onixFiles(props.region)}</ul>
            {props.version === "3.0" && props.region === REGIONS.US && (
                <>
                    <b>ONIX {props.version} License{` / ${props.label} License`}</b>
                    <ul>
                        <FileList period={"M"} type={"L"} region={props.region} version={props.version} />
                        <FileList period={"W"} type={"L"} region={props.region} version={props.version} />
                    </ul>
                </>
            )}
        </>
    );
}

export default OnixFile;
